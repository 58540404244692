import { BrowserRouter } from 'react-router-dom';

import Hero from './components/Hero';
import Features from './components/Features';

function App() {
  return (
    <BrowserRouter>
      <Hero />
      <Features />
    </BrowserRouter>
  );
}

export default App;
