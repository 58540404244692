const Features = () => {
  return (
    <section className="" id="features">
      <div className="max-w-6xl px-5 mx-auto md:mt-32 text-center">
        <h2 className="text-white text-4xl font-bold text-center">
          Solnaldo To The MOON (or binance listing, same thing really)
        </h2>
         <h3 className="text-white mt-10 text-2xl font-bold text-center">
          Please follow the socials and help us shill, we can lay the groundwork and foundation for a great meme but at
          the end of the day, we need the community to rally behind us to take it to the next level.
         </h3>

        <div className="flex flex-col mt-24 mb-12 md:mb-24 gap-4 md:flex-row md:space-x-6">
          <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-fuchsia-300 md:w-1/3">
            <h5 className="text-2xl font-bold">Twitter</h5>
            <p className="text-xl font-semibold text-black">
              Follow, repost, like, comment, and shill. You can do all of that good stuff <a href="https://x.com/Solnaldo_" target="_blank" rel="noopener noreferrer" className="">HERE</a>
            </p>
          </div>

          <div className="flex-col max-sm:mt-10 items-center p-6 space-y-6 rounded-lg bg-fuchsia-300 md:flex md:w-1/3">
            <h5 className="text-2xl font-bold">Telegram</h5>
            <p className="text-xl font-semibold text-black">
              Join our telegram <a href="https://t.me/solnaldo_siuuu" target="_blank" rel="noopener noreferrer" className="">HERE</a>
            </p>
          </div>

          <div className="flex-col max-sm:mt-10 items-center p-6 space-y-6 rounded-lg bg-fuchsia-300 md:flex md:w-1/3">
            <h5 className="text-2xl font-bold">TikTok</h5>
            <p className="text-xl font-semibold text-black">
              Currently, TikTok is MASSIVE for meme coins. Please follow and comment about Solnaldo <a href="https://www.tiktok.com/@solnaldo_" target="_blank" rel="noopener noreferrer" className="">HERE</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
