import React from "react";
import logo from "../assets/images/siu.PNG";

const Hero = () => {
  return (
    <section id="hero">
      <div className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
        <div className="flex flex-col md:ml-24 mb-32 space-y-12 md:w-1/2 md:order-2 items-center justify-center">
          <h1 className="text-white mt-16 text-4xl font-bold text-center md:text-5xl md:text-left">
            SIUUUUUUUUUU
          </h1>
          <p className="text-2xl font-semibold text-center text-gray-100 md:text-left">
            Meet Solnaldo, the GOAT of football now striving to be the #1 Solana meme coin.
            An absolutely BASED meme coin destined for greatness. We're just a chill team, but we work hard for our bags.
          </p>
          <p className="text-2xl font-semibold text-center text-gray-100 md:text-left">
            Don't be a jeet, become part of the Solnaldo community and become a BASED HODLER 👇👇👇
          </p>
          <div className="flex items-center justify-center md:justify-start space-x-4">
            <a
              href="https://birdeye.so/token/AvTm535Y9CEQFbLzvUi5fBkeGmGKx78LjniVNb23pump?chain=solana"
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 px-6 pt-2 text-black font-bold bg-fuchsia-300 rounded-full baseline hover:bg-fuchsia-200"
            >
              View Chart
            </a>

            <a
              href="https://pump.fun/coin/AvTm535Y9CEQFbLzvUi5fBkeGmGKx78LjniVNb23pump"
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 px-6 pt-2 text-black font-bold bg-fuchsia-300 rounded-full baseline hover:bg-fuchsia-200"
            >
              Buy Now
            </a>
          </div>
        </div>
        <div className="w-5/6 md:w-1/2 md:order-1">
          <img src={logo} alt="" className="rounded-xl w-5/6 h-auto mx-auto" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
